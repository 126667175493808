import { useState, useEffect, useRef } from "react";
import { useRouter } from "next/router";
import { GlobeAltIcon } from "@heroicons/react/outline";
import classNames from "classnames";

const LanguageSwitcher = ({ theme = "dark" }) => {
  const router = useRouter();
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  
  const defaultLocale = "es-MX";
  const availableLocales = [
    { code: "en", name: "English" },
    { code: "es-MX", name: "Español" },
  ];


  const getCurrentLocale = () => {
  // Server-side rendering safety
  if (typeof window === "undefined") return defaultLocale;
  
  // Priority: 
  // 1. Router query
  // 2. Local Storage
  // 3. Default locale
  const localeFromQuery = router.query.locale;
  const localeFromStorage = window.localStorage.getItem("amb-locale");
  
  return localeFromQuery || localeFromStorage || defaultLocale;
};

  const [currentLocale, setCurrentLocale] = useState(defaultLocale);
  
  // Update locale state on route changes
  useEffect(() => {
    setCurrentLocale(getCurrentLocale());
  }, [router.query.locale]);

  const handleLanguageSelect = (langCode) => {
  if (langCode !== currentLocale) {
    window.localStorage.setItem("amb-locale", langCode);
    router.replace(
      {
        pathname: router.pathname,
        query: { ...router.query, locale: langCode }
      },
      undefined,
      { shallow: true }
    );
  }
  setIsOpen(false);
};
// useEffect(() => {
//   console.log('Current Locale:', currentLocale);
//   console.log('Router Query Locale:', router.query.locale);
// }, [currentLocale, router.query.locale]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []); // No dependencies to avoid infinite loop

 // Find current language with fallback
  const currentLanguage = availableLocales.find(
    lang => lang.code === currentLocale
  )?.name || availableLocales[0].name;

  return (
    <div className="relative language-switcher z-20" ref={dropdownRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className={classNames(
          "flex items-center gap-2 px-4 py-2 text-sm font-medium border rounded-lg focus:outline-none",
          theme === "dark"
            ? "text-white hover:bg-black"
            : "text-gray-900 hover:bg-gray-100"
        )}
        aria-haspopup="true"
        aria-expanded={isOpen}
        aria-label="Select Language"
      >
        <GlobeAltIcon className="w-4 h-4" />
        <span>{currentLanguage}</span>
      </button>

      {isOpen && (
        <div
          className="absolute right-0 w-[200px] bg-white shadow-lg rounded-md mt-2 border"
          role="menu"
        >
          <div className="p-2 space-y-1">
            {availableLocales.map((lang) => (
              <button
                key={lang.code}
                onClick={() => handleLanguageSelect(lang.code)}
                className={classNames(
                  "block w-full px-4 py-2 text-sm font-medium rounded-md",
                  lang.code === currentLocale
                    ? theme === "dark"
                      ? "bg-black text-white"
                      : "bg-gray-200 text-black"
                    : theme === "dark"
                    ? "bg-gray-800 text-white hover:bg-black"
                    : "bg-gray-50 text-black hover:bg-gray-100"
                )}
                role="menuitem"
              >
                {lang.name}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default LanguageSwitcher;
