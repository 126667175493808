/* eslint-disable react/jsx-key */
import React, { useState, useContext, useEffect } from "react";
import { useRouter } from "next/router";
import Link from "next/link";

import PropTypes from "prop-types";
import classNames from "classnames";
import { SearchContext } from "@/components/lib/SearchBar";

import { Label } from "@/components/text/Label";
import { SearchIcon } from "@heroicons/react/outline";
import { GlobalLogo } from "../lib/SvgLogo";
import PopoverMenu from "../lib/PopoverMenu";
import Hamburger from "./Hamburger";
import { twMerge } from "tailwind-merge";
import { motion } from "framer-motion";
import PopSubMenu from "./PopSubMenu";
import { Popover, Transition } from "@headlessui/react";
import InitiativesPopover from "./InitiativesPopover";

import LanguageSwitcher from './LanguageSwitcher';
import { localeTexts } from "@/utils/locale";

const searchIconStyles = {
  dark: "text-white hover:text-neutral-800",
  light: "text-neutral-800 hover:text-white",
};

const dark_styles =
  "text-white/[.80]  border-b-4 border-b-transparent box-border border-b-4  hover:border-b-white ";
const light_styles =
  "text-neutral-700  border-b-4 border-b-transparent box-border border-b-4 hover:border-b-neutral-1000";

const themes = {
  light: light_styles,
  dark: dark_styles,
};
const linksStyles = {
  dark: "hover:bg-neutral-200",
  light: "hover:bg-white/80",
};

export default function Navbar({
  theme = "dark",
  items=navConfig,
  className,
}) {
  const router = useRouter();
  const [locale, setLocale] = useState("es-MX"); // Default
  const [open, setOpen] = useState(true);
  const base_styles = " flex gap-5 items-center p-3 lg:justify-center";


//  localization logic
  const localizedItems = items.map(item => {
    
    const key = item.label.toLowerCase();
    
    // correct locale
    const currentLocale = locale || "es-MX";
    
    //  access translations
    const translations = localeTexts[currentLocale]?.navbar?.[key] || {};
    
    
    return {
      ...item,
      // Use translated label if available, otherwise keep original
      label: translations.label || item.label,
      // Use translated href if available, otherwise keep original
      // href: translations.href || item.href
    };
  });

    // Sync locale with URL and localStorage
  useEffect(() => {
    if (!router.isReady) return;

    const urlLocale = router.query.locale;
    const storedLocale = typeof window !== "undefined" 
      ? localStorage.getItem("amb-locale") 
      : null;
    const finalLocale = urlLocale || storedLocale || "es-MX";

    setLocale(finalLocale);
    localStorage.setItem("amb-locale", finalLocale);
  }, [router.isReady, router.query.locale]);
  const border =
    theme === "light" ? "border-t-base-200" : "border-t-white/[.36]";

  const css = classNames(themes[theme], "px-3, py-4");
  return (
    <div className="flex w-full gap-5 md:justify-start md:align-middle lg:items-center">
      {/* <PopoverMenu theme="light" /> */}
      <div className="">
        <Link href="/" className="flex items-center">
          <GlobalLogo
            className={classNames(
              "sm:w-[188px] lg:w-[204px] ",
              theme === "light" ? "text-neutral-900" : "text-white"
            )}
          />
        </Link>
      </div>
      <nav className={classNames(className, "hidden grow lg:block ")}>
        <div className={classNames(base_styles, border)}>
          {localizedItems.map((e, i) => {
            const originalKey = items[i].label.toLowerCase();
            
            return originalKey === "programas" ? (
              <Popover key={i} as={React.Fragment}>
                {({ open }) => (
                  <>
                    {/* Preserved Popover.Button styling */}
                    <Popover.Button
                      className={classNames(
                        css,
                        "relative inline-flex cursor-pointer items-center px-2",
                        router.pathname.includes("iniciativa") && 
                          "bg-black text-white"
                      )}
                    >
                      {e.label}
                      {/* Preserved icon styling */}
                      {open ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-4 h-4 ml-1"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M4.5 15.75l7.5-7.5 7.5 7.5"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-4 h-4 ml-1"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                          />
                        </svg>
                      )}
                    </Popover.Button>

                    {/* Preserved Popover.Panel styling */}
                    <Transition
                      as={React.Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 -translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 -translate-y-1"
                    >
                      <Popover.Panel
                        as="div"
                        className="absolute top-14 z-[90] w-[800px] bg-white p-10"
                      >
                        <InitiativesPopover locale={locale} />
                      </Popover.Panel>
                    </Transition>
                  </>
                )}
              </Popover>
            ) : (
               	<a
                key={i}
                href={`${e.href}${locale ? `?locale=${locale}` : ''}`}
                className={classNames(
                  themes[theme],
                  "px-3 py-4",
                  theme === "light"
                    ? "hover:bg-black hover:text-white"
                    : "hover:bg-white/80 hover:text-black",
                    (router.pathname === items.href || 
                      router.asPath === items.href || 
                      router.asPath.startsWith(`${items.href}?`))? "bg-black text-white" : ""
                      )}
              >
                <Label size="lg" className="px-2">
                  {e.label}
                </Label>
              </a>
            );
          })}
        </div>
      </nav>
      <LanguageSwitcher theme={theme} />
    </div>
  );
}

export function Nav({ theme }) {
  const search_context = useContext(SearchContext);

  return (
    <>
      <div className="flex h-12 grow items-center align-middle md:h-[50px] lg:h-[76px]  ">
        <Navbar theme={theme} />
        <div
          className={classNames(
            theme === "light" ? "text-neutral-800" : "text-white",
            " flex h-full  cursor-pointer items-center px-3 align-middle hover:bg-black hover:text-white "
          )}
          onClick={() => search_context.setDisplay(!search_context.display)}
        >
          <SearchIcon className="w-6 h-6 " />
        </div>
        <Hamburger options={navConfig} theme={theme}/>
      </div>
    </>
  );
}

export function AmbulanteNav({ theme }) {
  const search_context = useContext(SearchContext);

  const linksStyle = twMerge(
    "cursor-pointer px-0 align-middle flex",
    linksStyles[theme]
  );

  const searchStyle = twMerge("w-5", searchIconStyles[theme]);

  return (
    <>
      <div className="flex h-12 grow items-center sm:mx-4 sm:gap-4 md:mx-8 md:h-[50px] lg:mx-[116px] lg:h-[76px] xl:mx-auto xl:max-w-[1208px]  ">
        <Navbar theme={theme} />
        <div
          onClick={() => search_context.setDisplay(!search_context.display)}
          className={linksStyle}
        >
          <SearchIcon className={searchStyle} />
        </div>
        <Hamburger options={navConfig} theme={theme} />
      </div>
    </>
  );
}

Navbar.propTypes = {
  theme: PropTypes.oneOf(["light", "dark"]),
};

export const navConfig = [
  {
    label: "Nosotros",
    href: "/sobre-nosotros",
    onClick: null,
    chevron: true,
  },
  {
    label: "Programas",
    href: "/iniciativas/gira",
    onClick: null,
    chevron: true,
  },
  {
    label: "Calendario",
    href: "/calendario",
    onClick: null,
    chevron: true,
  },
  {
    label: "Películas",
    href: "/catalogo",
    onClick: null,
    chevron: true,
  },
  {
    label: "Blog",
    href: "/blog",
    onClick: null,
    chevron: true,
  },
  {
    label: "Aliados",
    href: "/aliados",
    onClick: null,
    chevron: true,
  },
];
