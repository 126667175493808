import React, { useContext, useState } from "react";
import Link from "next/link";
import classNames from "classnames";
import * as Text from "@/components/text";
import Image from "next/legacy/image";
import { GlobalContext } from "../../stores/GlobalContextProvider";
import { localeTexts } from "@/utils/locale";

export default function InitiativesPopover({ locale = "es-MX" }) {
  const globalConfiguration = useContext(GlobalContext);
  const [showAllInitiatives, setShowAllInitiatives] = useState(false);
  
  // Get translations
  const t = localeTexts[locale]?.initiatives || {};
  const commonT = localeTexts[locale]?.common || {};

  const coreInitiatives = [
    {
      key: "gira",
      image: "/initiatives/gira.png",
      link: "/iniciativa/gira"
    },
    {
      key: "presenta",
      image: "/initiatives/presenta.png",
      link: "/ambulante-presenta"
    },
    {
      key: "masAlla",
      image: "/initiatives/mas-alla.png",
      link: "/ambulante-mas-alla"
    },
    {
      key: "ediciones",
      image: "/initiatives/ediciones.png",
      link: "/ambulante-ediciones"
    },
    // {
    //   key: "acervo",
    //   image: "/initiatives/acervo.jpeg",
    //   link: "https://acervoambulante.org/"
    // }
  ];

  const additionalInitiatives = [
    {
      key: "incidencias",
      image: "/initiatives/incidencias.png",
      link: "/incidencia",
      condition: globalConfiguration?.Incidencias
    },
    {
      key: "distribucion",
      image: "/initiatives/distribucion.png",
      link: "/distribucion",
      condition: globalConfiguration?.distribucion
    },
    {
      key: "becas_fondos",
      image: "/initiatives/becas-y-fondos.png",
      link: "/becas-y-fondos",
      condition: globalConfiguration?.becas_fondos
    },
    {
      key: "proyectos_especiales",
      image: "/initiatives/proyectos-especiales.png",
      link: "/proyectos-especiales",
      condition: globalConfiguration?.proyectos_especiales
    }
  ];

  return (
    <div>
      <div className="grid-cols-2 gap-4 space-y-1 lg:grid lg:space-y-0">
        {coreInitiatives.map((initiative) => (
          <InititativeListing
            key={initiative.key}
            image={initiative.image}
            link={initiative.link}
            title={t[initiative.key]?.title || "Programa"}
            description={t[initiative.key]?.description || ""}
            accentColor={initiative.key}
          />
        ))}
      </div>

      {/* Show more initiatives section */}
      {!showAllInitiatives && (
        <div className="mt-2 flex items-center justify-center">
          <Text.Body
            size="lg"
            className="cursor-pointer border-b-4 border-neutral-200 hover:border-neutral-400"
            onClick={() => setShowAllInitiatives(true)}
          >
            {/* {commonT.seeAllPrograms || "Ver todos los programas"} */}
          </Text.Body>
        </div>
      )}

      {showAllInitiatives && (
        <>
          <hr className="my-2 border" />
          <div className="grid-cols-2 gap-4 space-y-1 lg:grid lg:space-y-0">
            {additionalInitiatives.map((initiative) =>
              initiative.condition ? (
                <InititativeListing
                  key={initiative.key}
                  image={initiative.image}
                  link={initiative.link}
                  title={t[initiative.key]?.title || "Programa"}
                  description={t[initiative.key]?.description || ""}
                  accentColor={initiative.key}
                />
              ) : null
            )}
          </div>
        </>
      )}
    </div>
  );
}

function InititativeListing({
  locale="es-MX",
  image,
  accentColor,
  title,
  description,
  link,
  active = false
}) {
  const css = classNames(
    "min-h-[70px] flex align-middle items-center border-l-8 pl-2 hover:bg-neutral-100 cursor-pointer",
    accentColor ? `border-${accentColor}` : "border-red-500"
  );

  const commonT = localeTexts[locale]?.common || {};

  return (
    <div>
      <Link href={link} passHref>
        <div className={css}>
          <div className="hidden items-center align-middle lg:flex">
            <Image
              src={image}
              alt=""
              width={60}
              height={60}
              className="hidden max-w-full rounded object-cover lg:block"
            />
          </div>
          <div className="flex flex-1 flex-col px-1 py-2 md:px-5">
            <Text.Title
              as="p"
              size="md"
              className="flex items-center text-neutral-900"
            >
              {title}
              {active && (
                <Text.Overline
                  size="sm"
                  className="ml-2 rounded-sm bg-gira p-1 text-white"
                >
                  {commonT.now || "Ahora"}
                </Text.Overline>
              )}
            </Text.Title>
            <Text.Body size="md" className="text-neutal-600">
              {description}
            </Text.Body>
          </div>
        </div>
      </Link>
    </div>
  );
}